import React, { useState } from "react";
import "./FormInputs.scss";
import { BsEyeSlash, BsEye } from "react-icons/bs";
import { EmailIcon, PasswordLock, SearchIcon } from "../../Assets/icons";
import { BuildOutlined, UserAddOutlined } from "@ant-design/icons";

const iconMap = {
  icon1: EmailIcon,
  icon2: PasswordLock,
  icon3: SearchIcon,
  icon4: UserAddOutlined,
  icon5: BuildOutlined,
};

const FormInputs = ({
  title,
  type,
  value,
  onChange,
  name,
  placeholder,
  icon,
  onKeyDown,
  disabled,
  maxLength,
  min,
  max,
  className,
}) => {
  const IconComponent = iconMap[icon];
  const [showPassword, setShowPassword] = useState(false);

  const togglePasswordVisibility = () => {
    setShowPassword(!showPassword);
  };

  const handleInputChange = (e) => {
    let newValue = e.target.value;

    if (type === "number") {
      if (newValue === "" || /^0(\.\d{0,1})?$/.test(newValue)) {
        onChange(e.target.name, newValue);
      }
    } else {
      onChange(e.target.name, newValue);
    }
  };

  return (
    <div className="forminput">
      <span>{title}</span>
      <div
        className={`input-container ${icon ? "with-icon" : ""} ${
          disabled ? "input-disabled" : ""
        }`}
      >
        {IconComponent && (
          <div className="icon-container">
            <IconComponent className="input-icon" />
          </div>
        )}
        <input
          className="input "
          type={type === "password" && showPassword ? "text" : type}
          name={name}
          value={value}
          onChange={handleInputChange}
          placeholder={placeholder}
          onKeyDown={onKeyDown}
          maxLength={maxLength}
          disabled={disabled}
          min={min}
          max={max}
          step="0.1"
        />
        {type === "password" && (
          <div
            className="password-toggle-icon"
            onClick={togglePasswordVisibility}
          >
            {showPassword ? (
              <BsEyeSlash className="input-icon" />
            ) : (
              <BsEye className="input-icon" />
            )}
          </div>
        )}
      </div>
    </div>
  );
};

export default FormInputs;
