// Admin Auth
export const ADMIN_LOGIN_DETAILS = "admin/adminLogin/loginAdmin";
export const ADMIN_FORGOTPASSWORD = "admin/adminLogin/forgotAdminPassword";
export const ADMIN_RESETPASSWORD = "admin/adminLogin/resetAdminPassword";
export const ADMIN_LOGOUT = "admin/adminLogin/logoutAdmin";
export const ADMIN_REFRESH_TOKEN = "admin/adminLogin/refreshToken";

//Admin Pages
export const ADMIN_USER_MGMT = "admin/myaisquadUser/getMyAISquadUsers";
export const GET_ENTERPRISE_USERS = "admin/enterprise/getEnterpriseUser";
export const APPROVE_ENTERPRISE_USER = "admin/enterprise/approveEnterpriseUser";
export const UPDATE_USER_ENABLE_STATUS =
  "admin/enterprise/enableDisableEnterpriseUser";
export const UPDATE_MYAISQUAD_ENABLE_USER =
  "admin/myaisquadUser/enableDisableMyAISquadUser";
export const DELETE_ENTERPRISE_USER = "admin/enterprise/deleteEnterpriseUser";
export const DELETE_MYAISQUAD_USER = "admin/myaisquadUser/deleteMyAISquadUser";
export const GET_SUBSCRIPTION_USERS = "admin/enterprise/subscription/activeusers";
export const ADMIN_NEWS_LETTER = "admin/sendNewsLetter";
export const INACTIVE_USERS = "admin/enterprise/subscription/inactiveusers";
export const PRICING_BREAKDOWN_API = "admin/enterprise/subscription/getPricingBreakdown";
export const ADD_CUSTOM_PLAN = "admin/enterprise/assignCustomSubscription"

//EnterPrise Auth
export const GET_ALL_LLM_COUNT = "enterprise/llm/getllmcount";
export const ENTERPRISE_SIGNUP = "enterprise/signUp";
export const ENTERPRISE_VERIFY_ACCOUNT = "enterprise/verifyUser";
export const ENTERPRISE_LOGIN = "enterprise/login";
export const ENTERPRISE_FORGOT_PASSWORD = "enterprise/sendResetLinkOTP";
export const ENTERPRISE_RESET_PASSWORD = "enterprise/resetPassword";
export const ENTERPRISE_LOGOUT = "enterprise/logout";
export const ENTERPRISE_REFRESH_TOKEN = "enterprise/refreshToken";
export const ADD_NEW_LLM = "enterprise/llm/createllm";
export const GET_ALL_LLM_DETAILS = "enterprise/llm/getallllm";
export const UPDATE_LLM_DETAILS = "enterprise/llm/updatellm";
export const GET_ALL_FILE_DATA = "enterprise/llm/getllmfiles";
export const GET_ALL_MASTERPROMPT_HISTORY = "enterprise/conversations/promptrecord/enterpriseuser";
export const DELETE_LLM = "enterprise/llm/deletellm";
export const DELETE_FILE = "enterprise/llm/deletellmfile";
export const CREATE_API_KEY = "enterprise/llm/createapikey";
export const GET_API_KEY = "enterprise/llm/getallapikey";
export const DELETE_API_KEY = "enterprise/llm/deleteapikey";
export const CHAT_BOT_API = "enterprise/llm/chatbot";
export const ADD_SCRAPE_LINK = "enterprise/webScraper/webScraper";
export const GET_SCRAPE_LINK = "enterprise/webScraper/getAllScrapedLinks";
export const DELETE_SCRAPE_LINK = "enterprise/webScraper/deleteScrapedLink";
export const PAYMENT_API = "enterprise/subscription/stripePay/paymentsession";
export const CHECK_PAYMENT_STATUS = "enterprise/subscription/stripePay/subscriptiondetail";
export const PAYMENT_HISTORY = "enterprise/subscription/stripePay/getAllSession";
export const SUBSCRIPTION_STATUS = "enterprise/subscription/stripePay/subscriptionStatus";
export const CUSTOM_PAYMENT_PLAN = "enterprise/subscription/stripePay/customplanenquiry";
export const GET_PROFILE_DATA = "enterprise/getprofile"

//enterprise/User
export const ADD_LLM_USER = "enterprise/llm/user/create";
export const LLM_USER_LOGIN = "enterprise/llm/user/login";
export const LLM_USER_REQUEST_RESETPASSWORD = "enterprise/llm/user/requestresetPassword";
export const LLM_USER_RESETPASSWORD = "enterprise/llm/user/resetPassword"
export const LLM_USER_CHAT_BOT = "enterprise/llm/user/chatbot";
export const GET_ALL_LLM_USERS = "enterprise/llm/user/getallcreatedusers";
export const DELETE_LLM_USER = "enterprise/llm/user/deleteuser";
export const GET_LLM_NAME = "enterprise/llm/user/getllmname";
export const GET_ASSIGNED_LLMS = "enterprise/llm/user/getassignedllms";
export const GET_UNASSIGNED_LLMS = "enterprise/llm/user/getunassignedllms";
export const USER_ASSIGN_LLM = "enterprise/llm/user/assignllm";
export const USER_UNASSIGN_LLM = "enterprise/llm/user/removeassignedllm";
export const GET_ALL_USER_LLM_DETAILS = "enterprise/llm/user/getLLMList";
export const GET_USER_LLM_DETAILS = "enterprise/llm/user/getllmdetail";
export const UPDATE_USER_LLM_DETAILS = "enterprise/llm/user/updatellm";
export const UPLOAD_USER_LLM_FILE = "enterprise/llm/user/uploadfile";
export const GET_USER_LLM_FILE = "enterprise/llm/user/getllmfiles";
export const DELETE_USER_LLM_FILE = "enterprise/llm/user/deletellmfile";
export const ADD_USER_WEB_SCRAPER = "enterprise/llm/user/webScraper";
export const GET_USER_WEB_SCRAPER = "enterprise/llm/user/getAllScrapedLinks";
export const DELETE_USER_WEB_SCRAPER = "enterprise/llm/user/deleteScrapedLink";
export const CREATE_USER_API_KEY = "enterprise/llm/user/createapikey";
export const GET_USER_API_KEY = "enterprise/llm/user/getallapikey";
export const DELETE_USER_API_KEY = "enterprise/llm/user/deleteapikey";
export const GET_ADMIN_PROFILE_DATA = "enterprise/llm/user/adminprofile"
export const GET_ALL_MASTERPROMPT_USER_HISTORY = "enterprise/conversations/promptrecord/llmuser"


// chat history

export const GET_CHAT_HISTORY_ENTERPRICE = "enterprise/conversations/enterprise"
export const GET_CHAT_HISTORY_LLM_USER = "enterprise/conversations/llmuser"












